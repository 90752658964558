.list-obras {
    overflow-x: auto;
    max-height: 80vh;
}

.hearder-obra{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.controller-obra{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

#is-sumary{
    width: auto;
    display: flex;
    align-items: center;
}
#is-sumary input{
    transition: 0.2s linear;
    cursor: pointer;
    padding: 0 !important;
}

#is-sumary label{
    width: 100px;
    font-size: 1rem;
    padding: 0 !important;
}

.input-group-obra{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 2px;
}

.input-group-obra span{
    font-size: 0.65rem;
    text-transform: uppercase;
    color: var(--font-color--input);
    font-weight: bold;
}

.input-group-obra input, .input-group-obra  select{
    width: 160px;
    font-size: var(--font-color--input);
    text-transform: uppercase;
}
