.card-obra{
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 5px;
    flex-basis: 300;
    width: 300px;
    height: auto;
}

.card-obra img{ 
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: 100%;
    height: 240px;
    border: 1px solid rgb(233, 229, 229);
}


.card-obra .title{ 
    background-color: #293846;
    color: white;
    width: 100%;
    padding: 8px;
    font-size: 0.8rem;
    text-transform: uppercase;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-obra .type{ 
    background-color: #0f1a24;
    color: #47769C;
    width: 100%;
    padding: 8px;
    font-size: 0.7rem;
    text-transform: uppercase;
    font-weight: bold;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.card-obra div span:nth-child(2){ 
    width: 80%;
    background-color: white;
    font-size: 0.8rem;
}

