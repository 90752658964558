.action-obra{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding: 10px 0;
}

table{
    transition: 1s linear;
}