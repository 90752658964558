:root {
    --bg-color: rgb(238, 237, 237);
    --txt-color: rgb(60, 110, 202);
}

.container-home{
    height: auto !important;
}

.body-chart{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.nav-header {
    width: 100%;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--bg-color);
    /* background-color: whitesmoke !important;
    border-bottom: 1px solid rgb(187, 184, 184);
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.12), 0 1px 0px rgba(0, 0, 0, 0.24);*/
}

.body-empresa span {
    font-size: 1.4rem;
    font-weight: bold;
    color: var(--txt-color);
    text-transform: uppercase;
}

.body-empresa {
    display: flex;
    align-items: center;
    gap: 15px;
}

.logos {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
}

.logos img {
    border-radius: 5px;
    background-color: white;
    padding: 10px;
}

.controller {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 5px 20px;
    background-color: var(--bg-color);
}

.controller select {
    width: 180px;
    font-size: 0.8rem;
    box-shadow: 3px 5px rgba(0, 0, 0, 0.10);
    cursor: pointer;
}