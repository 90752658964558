
.tab-obra{
    background-color: var(--bg-color);
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.12);
}
.content{
    display: flex;
    flex-direction: column;
    height: auto !important;
    padding: 10px;
}

.painel{
    width: auto;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 10px;
}

.charts{
    width: 100%;
    height: 850px;
    overflow-x: auto;
}

.chart-body{
    width: 100%;
    height: auto;
    display: flex;
}

.contador{
    display: flex;
    align-items: center;
    gap: 10px;
}

.contador div{
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    padding: 10px 20px;
    border: solid 1px #415280;
    border-radius: 10px;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.12), 0 1px 0px rgba(0, 0, 0, 0.24)
}

.contador div span:nth-child(1){
   font-size: 1.5rem;
}

.contador div span:nth-child(1)[name="red"]{
    color: red;
 }

 .contador div span:nth-child(1)[name="green"]{
    color: green;
 }

 
.body-loading{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px;
  }