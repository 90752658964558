
.indicativos{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;
    border-radius: 5px;
}

.indicativos .info{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex: 1;
    height: 200px;
}

.indicativos .info .grupo-dados{
    display: flex;
    flex-direction: column;
   
}

.indicativos .body-percentual{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    flex: 1;
    height: 200px;
}

.indicativos .percentual{
    border-radius: 50%;
    height: 140px;
    width: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
}

