.action-filter{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 12px;
}

.action-filter select {
    width: 180px;
    box-shadow: 3px 5px rgba(0, 0, 0, 0.10);
}

.action-filter span{
    color:var(--txt-color);
    font-size: 1.2rem;
    font-weight: bold;
}

.icon-clear{
    font-size: 0.9rem;
    color: brown;
    cursor: pointer;
}

.icon-clear:hover{
    font-weight: bold;
    color: goldenrod;
    transition: 1s linear;
    scale: 1.1;
}

.action-filter-body span:hover{
    background-color: goldenrod;
    transition: 0.3s linear;
}

.icon-close{
    padding-left: 5px;
}

.action-filter-body{
    display: flex;
    width: auto;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}

.action-filter-body span{
    color: whitesmoke;
    font-size: 0.7rem;
    background-color: brown;
    padding: 3px 10px;
    border-radius: 5px;
    cursor: pointer;
}

#custom-switch{
    transition: 0.2s linear;
    cursor: pointer;
}

.tabela{
    height: auto !important;

}