#chart-justificativa{
    height: 450px;
    width: 80%;
    cursor: pointer;
}

.body-chart{
    padding: 20px 10px;
}

.body-chart span{
    color:var(--txt-color);
    font-size: 1.2rem;
    font-weight: bold;
}